import React from 'react'
import './Projects.css'

export default function Projects() {
    return (<>
        {/* <h1 className='center' style={{marginBottom:'15px', fontSize:40}}>P&emsp;&emsp;R&emsp;&emsp;O&emsp;&emsp;J&emsp;&emsp;E&emsp;&emsp;C&emsp;&emsp;T&emsp;&emsp;S</h1> */}
        <div className='container'>
            <div className='wrapper'>
                <div className='card'>
                    <img src='https://res.cloudinary.com/dchk5msou/image/upload/c_crop,w_1335/v1647640976/Projects/Covers/154706147_3147837538652347_3670982157888343085_n_lv7zga.jpg'/>
                    <div className='info'>
                        <h1>Portrait</h1>
                        <p>Proident proident labore qui voluptate in nostrud non mollit nostrud sit aliqua exercitation laborum. </p>
                        <button>See More</button>
                    </div>
                </div>
                <div className='card'>
                    <img src='https://res.cloudinary.com/dchk5msou/image/upload/v1647640976/Projects/Covers/124624901_2888852494550854_766645298956963618_n_lbcazc.jpg'/>
                    <div className='info'>
                        <h1>Fashion</h1>
                        <p>Proident proident labore qui voluptate in nostrud non mollit nostrud sit aliqua exercitation laborum. </p>
                        <button>See More</button>
                    </div>
                </div>
                <div className='card'>
                    <img src='https://res.cloudinary.com/dchk5msou/image/upload/v1647640976/Projects/Covers/180065389_3324217777680988_2701678842862859280_n_v6gkkg.jpg'/>
                    <div className='info'>
                        <h1>Product</h1>
                        <p>Proident proident labore qui voluptate in nostrud non mollit nostrud sit aliqua exercitation laborum. </p>
                        <button>See More</button>
                    </div>
                </div>
            </div>
        
        </div></>
    )
}