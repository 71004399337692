import React, { useRef } from 'react'
import './style.css'
import logo1 from '../../assets/images/3_Sigla Preda Photo Events_PNG-min.webp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FaIcons from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'

const HomePage = () => {

    //TODO: Solve scroll buttons bug
    const studioRef = useRef(null);
    const artRef = useRef(null);
    const eventsRef = useRef(null);

    const scrollTo = (ref) => {
        ref.current?.scrollIntoView({
            behavior: 'smooth',
            block: "center"
        });
      };

    return (
        <>
            <Navbar contact={false}/>
            <div className='home-container'>
                <div className='sub-category-container'>
                    <div className='home-title golden-text'>
                        FOTOGRAFIE - VIDEOGRAFIE <br/> STUDIO
                    </div>
                    <img src={logo1} ref={studioRef}/>
                    <div className='home-subtitle golden-text'>
                        Ședințe Foto, Colaborări Fashion Designeri, <br/> Fotografie de Produs
                    </div>
                    <FontAwesomeIcon className='scroll-icon' icon={FaIcons.faChevronDown} size="2xl" onClick={() => scrollTo(artRef)}/>
                </div>
                <div className='sub-category-container'>
                    <FontAwesomeIcon className='scroll-icon' icon={FaIcons.faChevronUp} size="2xl" onClick={() => scrollTo(studioRef)}/>
                    <Link className='home-link' to="/studio">
                        <div className='home-title golden-text'>
                            FOTOGRAFIE - VIDEOGRAFIE <br/> ARTĂ
                        </div>
                        <img src={logo1} ref={artRef}/>
                        <div className='home-subtitle golden-text'>
                            Proiecte Personale, Solo Show-uri, Expoziții <br /> Colective, Licitații de Artă, Premii
                        </div>
                    </Link>
                    <FontAwesomeIcon className='scroll-icon' icon={FaIcons.faChevronDown} size="2xl" onClick={() => scrollTo(eventsRef)}/>
                </div>
                <div className='sub-category-container'>
                    <FontAwesomeIcon className='scroll-icon' icon={FaIcons.faChevronUp} size="2xl" onClick={() => scrollTo(artRef)}/>
                    <div className='home-title golden-text'>
                        FOTOGRAFIE - VIDEOGRAFIE <br/> EVENIMENTE
                    </div>
                    <img src={logo1} ref={eventsRef}/>
                    <div className='home-subtitle golden-text'>
                        Petreceri Personale, Nuntă, Botez, Majorat, <br /> Absolvenți, Albume, Ședințe Foto Tematice
                    </div>
                </div>
            </div>
            <Footer/>
        </>
  )
}

export default HomePage