import React from 'react'
import { FacebookProvider, EmbeddedPost, SendToMessenger } from 'react-facebook';
import InstagramEmbed from 'react-instagram-embed';


export default function News() {
    return (
        <>
        {/* <div className="page-title">
            Social Media Feed
        </div>
        <div className="facebook-feed">
            <FacebookProvider appId="410714803799810">
                <EmbeddedPost href="https://www.facebook.com/silviu.preda.3/posts/3553655711403859" width="100" />
                <EmbeddedPost href="https://www.facebook.com/silviu.preda.3/posts/3603111616458268" width="100" />
                <EmbeddedPost href="https://www.facebook.com/silviu.preda.3/posts/3635516959884400" width="100" />     
                <EmbeddedPost href="https://www.facebook.com/silviu.preda.3/posts/3555528427883254" width="100" />     
                <EmbeddedPost href="https://www.facebook.com/silviu.preda.3/posts/3547901138645983" width="100" />
            </FacebookProvider>
        </div>
        <div className="instagram-feed">
            <InstagramEmbed
                url='https://instagr.am/p/Zw9o4/'
                clientAccessToken='123|456'
                maxWidth={320}
                hideCaption={false}
                containerTagName='div'
                protocol=''
                injectScript
                onLoading={() => {}}
                onSuccess={() => {}}
                onAfterRender={() => {}}
                onFailure={() => {}}
            />
        </div> */}
        </>
    )
}