import React from 'react'
import './style.css'

const Footer = () => {
  return (
    <div className='footer-container'>
        <div className='footer-content golden-text'>
            <i className="far fa-copyright" /> 2023: SILVIU PREDA | <br className='footer-break'/> ALL RIGHTS RESERVED
        </div>
    </div>
  )
}

export default Footer