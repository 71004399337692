import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FaIcons from '@fortawesome/free-solid-svg-icons'
import './style.css'
import contact_image from '../../assets/images/contact.png'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const Navbar = ({ contact }) => {

    const [searchQuery, setSearchQuery] = useState('');

    const search = () => console.log("search");

  return (
    <div className='navbar'>
        <div className={contact ? 'navbar-contact-icon contact-version' : 'navbar-contact-icon'}>
            <Link className='home-link' to="/contact">
                <img src={contact_image} />
            </Link>
        </div>
        <div className={contact ? 'navbar-title contact-version' : 'navbar-title'}>
            <a href='#'>
                {contact ? <>CONTACT AND SOCIAL MEDIA</> : <>SILVIU PREDA</>}
            </a>
        </div>
        <div className='search-bar'>
            <div className='search-border'>
                <form action="#" onSubmit={search}>
                    <input type="search" className="search-data" placeholder="Search..." required onChange={e => setSearchQuery(e.target.value)}/>
                    {/* <button type="submit" className="fas fa-search"></button> */}
                </form>
            </div>
            <div className='search-icon-border'>
                <FontAwesomeIcon className="search-icon" icon={FaIcons.faMagnifyingGlass} size='lg' color='white' />
            </div>
        </div>
    </div>
  )
}

export default Navbar