import React from 'react'
import profile from '../../images/profile.jpg'

export default function Biography() {
    return (
        <div className='biography'>
            <h1 className='page-title'>Biography</h1>
            <div className='biography-content'>
                <img src={profile} />
                <div className='biography-text'>
                    Proident proident labore qui voluptate in nostrud non mollit nostrud sit aliqua exercitation laborum. Deserunt sunt mollit dolore sint voluptate. Qui duis amet ullamco elit deserunt exercitation ipsum incididunt id pariatur laboris est. In deserunt culpa duis est aute anim sit.Consectetur esse cillum sint cillum esse anim ut nostrud est adipisicing qui adipisicing et. Ipsum ullamco aute nulla exercitation nulla tempor quis nisi magna nisi Lorem velit laboris. Et occaecat incididunt proident eiusmod nulla ad et irure adipisicing qui irure occaecat sunt. Exercitation commodo dolore irure ad amet culpa nisi.
                    <br /><br />

                    Anim est laboris do ullamco commodo. Velit et qui incididunt duis elit. Deserunt officia eiusmod enim adipisicing eiusmod minim reprehenderit exercitation quis elit. Elit voluptate magna ea elit. Aliquip do eiusmod ullamco aute quis fugiat esse tempor minim cupidatat est.

                    Duis proident cillum ad amet. Deserunt mollit fugiat et minim. Ipsum qui sint cillum laboris. Incididunt Lorem minim aliqua voluptate. Quis cillum pariatur proident minim. Nulla eiusmod voluptate aliquip excepteur magna ea esse reprehenderit ullamco laborum.
                    <br /><br />
                    Deserunt laboris laborum nostrud nostrud tempor. Minim tempor pariatur enim adipisicing dolore proident ad irure consequat consequat nulla cupidatat officia. Consectetur do laboris enim dolor aliquip. Qui qui tempor Lorem nostrud amet irure laborum laboris anim do laborum sit nisi sint.

                    Id ut mollit tempor aliquip cupidatat aliqua sit nisi pariatur. Anim magna ut qui ipsum ipsum enim. Eiusmod exercitation irure voluptate nostrud Lorem Lorem quis fugiat ea ex consectetur eu. Minim aute nulla consectetur veniam proident ad aliquip ullamco officia non aliquip laboris.
                    <br /><br />

                    Eu laboris fugiat ea veniam adipisicing adipisicing qui exercitation. Dolor nulla pariatur cupidatat duis pariatur nostrud magna nulla minim ut. Cillum ex elit cupidatat amet proident velit ea laborum velit tempor consequat dolore. Nostrud excepteur nisi sunt sint cupidatat. Voluptate qui ipsum aliqua reprehenderit. Adipisicing occaecat veniam elit tempor excepteur enim laborum Lorem excepteur ea adipisicing dolor.
                    Consequsat et esse ex adipisicing excepteur exercitation id tempor ut amet.
                    Deserunt laboris laborum nostrud nostrud tempor. Minim tempor pariatur enim adipisicing dolore proident ad irure consequat consequat nulla cupidatat officia. Consectetur do laboris enim dolor aliquip. Qui qui tempor Lorem nostrud amet irure laborum laboris anim do laborum sit nisi sint.

                    Id ut mollit tempor aliquip cupidatat aliqua sit nisi pariatur. Anim magna ut qui ipsum ipsum enim. Eiusmod exercitation irure voluptate nostrud Lorem Lorem quis fugiat ea ex consectetur eu. Minim aute nulla consectetur veniam proident ad aliquip ullamco officia non aliquip laboris.
                    <br /><br />

                    Eu laboris fugiat ea veniam adipisicing adipisicing qui exercitation. Dolor nulla pariatur cupidatat duis pariatur nostrud magna nulla minim ut. Cillum ex elit cupidatat amet proident velit ea laborum velit tempor consequat dolore. Nostrud excepteur nisi sunt sint cupidatat. Voluptate qui ipsum aliqua reprehenderit. Adipisicing occaecat veniam elit tempor excepteur enim laborum Lorem excepteur ea adipisicing dolor.
                    Consequat et esse ex adipisicing excepteur exercitation id tempor ut amet.
                    Deserunt laboris laborum nostrud nostrud tempor. Minim tempor pariatur enim adipisicing dolore proident ad irure consequat consequat nulla cupidatat officia. Consectetur do laboris enim dolor aliquip. Qui qui tempor Lorem nostrud amet irure laborum laboris anim do laborum sit nisi sint.

                    Id ut mollit tempor aliquip cupidatat aliqua sit nisi pariatur. Anim magna ut qui ipsum ipsum enim. Eiusmod exercitation irure voluptate nostrud Lorem Lorem quis fugiat ea ex consectetur eu. Minim aute nulla consectetur veniam proident ad aliquip ullamco officia non aliquip laboris.
                    <br /><br />

                    Eu laboris fugiat ea veniam adipisicing adipisicing qui exercitation. Dolor nulla pariatur cupidatat duis pariatur nostrud magna nulla minim ut. Cillum ex elit cupidatat amet proident velit ea laborum velit tempor consequat dolore. Nostrud excepteur nisi sunt sint cupidatat. Voluptate qui ipsum aliqua reprehenderit. Adipisicing occaecat veniam elit tempor excepteur enim laborum Lorem excepteur ea adipisicing dolor.
                    Consequat et esse ex adipisicing excepteur exercitation id tempor ut amet.
                    Deserunt laboris laborum nostrud nostrud tempor. Minim tempor pariatur enim adipisicing dolore proident ad irure consequat consequat nulla cupidatat officia. Consectetur do laboris enim dolor aliquip. Qui qui tempor Lorem nostrud amet irure laborum laboris anim do laborum sit nisi sint.
                    <br /><br />

                    Id ut mollit tempor aliquip cupidatat aliqua sit nisi pariatur. Anim magna ut qui ipsum ipsum enim. Eiusmod exercitation irure voluptate nostrud Lorem Lorem quis fugiat ea ex consectetur eu. Minim aute nulla consectetur veniam proident ad aliquip ullamco officia non aliquip laboris.

                    Eu laboris fugiat ea veniam adipisicing adipisicing qui exercitation. Dolor nulla pariatur cupidatat duis pariatur nostrud magna nulla minim ut. Cillum ex elit cupidatat amet proident velit ea laborum velit tempor consequat dolore. Nostrud excepteur nisi sunt sint cupidatat. Voluptate qui ipsum aliqua reprehenderit. Adipisicing occaecat veniam elit tempor excepteur enim laborum Lorem excepteur ea adipisicing dolor.
                    Consequat et esse ex adipisicing excepteur exercitation id tempor ut amet.
                    <br /><br />

                    Eu laboris fugiat ea veniam adipisicing adipisicing qui exercitation. Dolor nulla pariatur cupidatat duis pariatur nostrud magna nulla minim ut. Cillum ex elit cupidatat amet proident velit ea laborum velit tempor consequat dolore. Nostrud excepteur nisi sunt sint cupidatat. Voluptate qui ipsum aliqua reprehenderit. Adipisicing occaecat veniam elit tempor excepteur enim laborum Lorem excepteur ea adipisicing dolor.
                    Consequat et esse ex adipisicing excepteur exercitation id tempor ut amet.
                    Deserunt laboris laborum nostrud nostrud tempor. Minim tempor pariatur enim adipisicing dolore proident ad irure consequat consequat nulla cupidatat officia. Consectetur do laboris enim dolor aliquip. Qui qui tempor Lorem nostrud amet irure laborum laboris anim do laborum sit nisi sint.
                    <br /><br />

                    Id ut mollit tempor aliquip cupidatat aliqua sit nisi pariatur. Anim magna ut qui ipsum ipsum enim. Eiusmod exercitation irure voluptate nostrud Lorem Lorem quis fugiat ea ex consectetur eu. Minim aute nulla consectetur veniam proident ad aliquip ullamco officia non aliquip laboris.

                    Eu laboris fugiat ea veniam adipisicing adipisicing qui exercitation. Dolor nulla pariatur cupidatat duis pariatur nostrud magna nulla minim ut. Cillum ex elit cupidatat amet proident velit ea laborum velit tempor consequat dolore. Nostrud excepteur nisi sunt sint cupidatat. Voluptate qui ipsum aliqua reprehenderit. Adipisicing occaecat veniam elit tempor excepteur enim laborum Lorem excepteur ea adipisicing dolor.
                    Consequat et esse ex adipisicing excepteur exercitation id tempor ut amet.
                </div>
            </div>
        </div>
    )
}