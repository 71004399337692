import React from 'react'
import './style.css'

const StudioPage = () => {
  return (
    <div className='studio-page-container'>
      StudioPage
    </div>
  )
}

export default StudioPage