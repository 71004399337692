import React from 'react'
import tiktok_icon from '../../assets/images/tiktok.png'
import './style.css'

const ContactCard = ({ data }) => {
  return (
    <div className='contact-element'>
        <div className='contact-element-title'>
            {data['title']}
        </div>
        <hr />
        <div className='contact-data'>

            <a href={data["info"]["phone"]["url"]} target='_blank'>
                <div className='contact-entry'>
                    <div className='contact-icon'>
                        <img alt='whatsapp icon' src={tiktok_icon} />
                    </div>
                    <div className='contact-text'>
                        {data['info']['phone']['content']} <span> {'>'} </span>
                    </div>
                </div>
            </a>

            <a href={data["info"]["phone"]["url"]} target='_blank'>
                <div className='contact-entry'>
                    <div className='contact-icon'>
                        <img alt='email icon' src={tiktok_icon} />
                    </div>
                    <div className='contact-text'>
                        {data['info']['email']['content']} <span> {'>'} </span>
                    </div>
                </div>
            </a>

            <a href={data["info"]["phone"]["url"]} target='_blank'>
                <div className='contact-entry'>
                    <div className='contact-icon'>
                        <img alt='instagram icon' src={tiktok_icon} />
                    </div>
                    <div className='contact-text'>
                        {data['info']['insta']['content']} <span> {'>'} </span>
                    </div>
                </div>
            </a>
            
            <a href={data["info"]["phone"]["url"]} target='_blank'>
                <div className='contact-entry'>
                    <div className='contact-icon'>
                        <img alt='facebook icon' src={tiktok_icon} />
                    </div>
                    <div className='contact-text'>
                        {data['info']['facebook']['content']} <span> {'>'} </span>
                    </div>
                </div>
            </a>

            <a href={data["info"]["phone"]["url"]} target='_blank'>
                <div className='contact-entry'>
                    <div className='contact-icon multiple'>
                        <img alt='pinterest icon' src={tiktok_icon} />
                        <img alt='twitter icon' src={tiktok_icon} />
                        <img alt='tiktok icon' src={tiktok_icon} />
                    </div>
                    <div className='contact-text'>
                        {data['info']['others']['content']} <span> {'>'} </span>
                    </div>
                </div>
            </a>
        </div>
    </div>
  )
}

export default ContactCard