import React, { useState } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import './style.css'
import ContactCard from '../../components/ContactCard/ContactCard'
import contact_image from '../../assets/images/contact_image.png'
import contact_data from './contact_data.json'

const ContactPage = () => {

    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const submitMessage = (e) => {
        e.preventDefault();
        // TODO: Send email
        console.log(message)
        setEmail('');
        setSubject('');
        setMessage('');
    }

  return (
    <>
        <Navbar contact={true}/>
        <div className='contact-page-container'>
            <div className='contact-row'>
                <ContactCard data={contact_data[0]} />
                <form className='contact-page-form'>
                    CONTACT US <br />
                    <input type='email' placeholder='Email address' value={email} onChange={(e) => setEmail(e.target.value)}/> <br />
                    <input type='text' placeholder='Subject' value={subject} onChange={(e) => setSubject(e.target.value)}/>
                    <textarea rows={5} placeholder='Message' value={message} onChange={(e) => setMessage(e.target.value)}/>
                    <button type='submit' onClick={submitMessage}>
                        SUBMIT
                    </button>
                </form>
            </div>
            <div className='contact-row'>
                <ContactCard data={contact_data[1]} />
                <ContactCard data={contact_data[2]} />
                <div className='contact-image'>
                    <img src={contact_image} />
                </div>
            </div>            
        </div>
    </>
  )
}

export default ContactPage