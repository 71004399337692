import React from 'react';
import './App.css';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import News from './components/pages/News';
import Biography from './components/pages/Biography';
import Projects from './components/pages/Projects';
import Maintenance from './MaintenancePage/Maintenance';
import HomePage from './pages/HomePage/HomePage';
import StudioPage from './pages/StudioPage/StudioPage';
import ContactPage from './pages/ContactPage/ContactPage';

const maintenance = false;

const App = () => {
    return (
        <>
        {maintenance ? (
            <Maintenance/>
        ) : (
        <Router>
            {/* <Navbar /> */}
            <Switch>
                <Route path='/' exact component={HomePage} />
                <Route path='/studio' exact component={StudioPage} />
                <Route path='/news' exact component={News} />
                <Route path='/biography' exact component={Biography} />
                <Route path='/projects' exact component={Projects} />
                <Route path='/shop' component={() => { 
                    window.location.replace('https://shop.silviupreda.art/'); 
                    return null;
                }}/>
                <Route path='/contact' exact component={ContactPage} />
                <Route path='/admin' component={() => { 
                    window.location.replace('https://admin.silviupreda.art/'); 
                    return null;
                }}/>
            </Switch>
            {/* <Footer /> */}
        </Router>

        
        // <Container maxidth="lg">
        //     <AppBar position="static" color ="inherit">
        //         <Typography variant="h2" align="center">Silviu Preda</Typography>
        //         <img src={memories} alt="memories" height = "60"/>
        //     </AppBar>
        // </Container>
        )}
        </>
    )
}

export default App;
