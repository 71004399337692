import React from 'react'
import "./maintenance.css"
import Grid from '@material-ui/core/Grid';


document.addEventListener("contextmenu", (event) => {
  event.preventDefault();
});

const Maintenance = () => {
  
  return (
    <>
      <div className='background-image'>
      </div>
      <div className='title'>Under Construction</div>

      <div className='social'>
        <Grid container direction='column' justifyContent='space-between' alignItems='center'>
          <Grid item>
            <div className='copyright'>
              <i class="far fa-copyright" /> Silviu Preda
            </div>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
            
              <Grid item>
                <a href='https://www.facebook.com/silviu.preda.3' target="_blank"><i class='fab fa-facebook-square fa-lg' /></a>
              </Grid>

              <Grid item>
                <a href='https://www.instagram.com/silviupreda' target="_blank"><i class='fab fa-instagram fa-lg' /></a>
              </Grid>

              <Grid item>
                <a href='https://twitter.com/silviupreda_' target="_blank"><i class="fab fa-twitter-square fa-lg"/></a>
              </Grid>

              <Grid item>
                <a href='https://www.tiktok.com/@silviupreda_' target="_blank"><i class="fab fa-tiktok"></i></a>
              </Grid>

              <Grid item>
                <a href='https://www.pinterest.com/silviu_preda_studio/' target="_blank"><i class="fab fa-pinterest-square fa-lg"></i></a>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Maintenance